import logo from './logo-img.png';
import appBtn from './appstore-button.svg'
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="image__logo">
        <img src={logo} alt="" className="logo" />
      </div>
      <h1 className="title">AI Chatbot App with Сhat</h1>
      <p className="text">Work faster, study smarter,<br />and make your life easier<br />with smart AI Chatbot!</p>
      <a href="https://apps.apple.com/us/app/id6446992925" className="link">
        <img src={appBtn} alt="" className="button__img" />
      </a>
    </div>
  );
}

export default App;
